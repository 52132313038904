import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme';

const globalStyles = (theme) => ({
  '*::-webkit-scrollbar': {
    width: '8px',
  },
  '*::-webkit-scrollbar-track': {
    backgroundColor: theme.palette.primary.background,
  },
  '*::-webkit-scrollbar-thumb': {
    border: `2px solid ${theme.palette.grey.main}`,
    borderRadius: '5px',
    backgroundColor: theme.palette.header.main,
    '&:hover': {
      backgroundColor: theme.palette.header.light,
    },
  },
});

export const MuiThemeProvider = ({ children, getUserTheme = () => {} }) => {
  const mergeTheme = {
    ...theme,
    ...getUserTheme(theme),
  };

  return (
    <ThemeProvider theme={mergeTheme}>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      {children}
    </ThemeProvider>
  );
};
