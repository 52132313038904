import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Box, Container} from '@mui/material';
import styled from '@emotion/styled';

import useHttp from '../../hooks/useHttp';

import {Chat} from '../Chat/Chat';
import {EnterYourData} from './EnterYourData';
import {Dialog} from '../Dialog/Dialog';

import {getDayName} from '../../utils';

import {ReactComponent as ChatIcon} from '../../assets/icons/chatbutton.svg';


import {toZonedTime} from 'date-fns-tz';
import {translates} from '../../constants/translates';

const widgetId = localStorage.getItem('widgetId');

// widget & button sizes
const cW = 360;
const cH = 600;
const bntSize = 64;

const MainStyled = styled(Container)(({ theme, isRequestpersonalDataShown, isLeaveDialogBlockShown, isSmallScreen }) => ({
    overflow: 'hidden',
    position: 'fixed',
    height: '100%',
    maxHeight: '600px',
    maxWidth: '360px',
    fontFamily: theme.typography.fontFamily,
    borderRadius: theme.shape.window,
    '&::before': {
        content: '""',
        position: 'absolute',
        top: !isRequestpersonalDataShown && !isLeaveDialogBlockShown ? '6px' : '0px',
        left: 0,
        width: '100%',
        height: !isRequestpersonalDataShown && !isLeaveDialogBlockShown ? '590px' : '600px',
        zIndex: -1,
        borderRadius: theme.shape.window,
        backgroundColor: theme.palette.primary.background,
    },
    ...(isSmallScreen && {
        maxHeight: '100%',
        maxWidth: '100% !important',
        borderRadius: 0,
        backgroundColor: theme.palette.primary.background,
        '&::before': {
            height: '100% !important',
            borderRadius: 0,
        },
    }),
}));

export const Main = ({
                         socketInstance,
                         radius,
                         name,
                         schedule,
                         titleMessages,
                         timezone,
                         currentFont,
                         channels,
                         logotype,
                         defaultLang,
                         setIsTokenChanged,
                         requestedFields,
                         visitorMessages,
                         copyDisabled,
                         position = 'bottom-right',
                         margins = [10, 10],
                         timeout = 0,
                         showWidget = true,
                     }) => {
    const isDialogActive = localStorage.getItem('dialogStatus') === 'active';
    const [isChatActive, setChatActive] = useState(false);
    const [isWidgetShown, setWidgetShown] = useState(true);
    const [isRequestpersonalDataShown, setRequestpersonalDataShown] =
        useState(false);
    const [isLeaveDialogBlockShown, setLeaveDialogBlockShown] = useState(false);
    const [isAddYourDataVisible, setIsAddYourDataVisible] = useState(false);
    const [isTimeout, setIsTimeout] = useState(false);
    const [selfClosed, setSelfClosed] = useState(true);
    const [messageText, setMessageText] = useState('');
    const [isOnline, setIsOnline] = useState(false);

    const [dialogId, fetchDialogId] = useHttp(`widget/${widgetId}/dialog`);
    const [messages, setMessages] = useState([]);
    const [isPreload, setPreload] = useState(false);

    const [messagesData, messagesRequest, isLoading] = useHttp(
        `widget/${widgetId}/dialog/${dialogId?.data?.id}${
            isPreload ? '?preload=1' : ''
        }`
    );
    const [message, sendMessageRequest, error] = useHttp(
        `widget/${widgetId}/dialog/${dialogId?.data?.id}?text=${encodeURIComponent(
            messageText
        )}`
    );
    const [_, closeDialog] = useHttp(
        `widget/${widgetId}/dialog/${dialogId?.data?.id}/close`
    );

    const currentDate = new Date();
    const zonedDate = toZonedTime(currentDate, timezone);
    const scheduleDay = getDayName(currentDate);
    const scheduleStart = new Date(
        `${zonedDate.toDateString()} ${schedule[scheduleDay]?.start}`
    );
    const end = new Date(
        `${zonedDate.toDateString()} ${schedule[scheduleDay]?.end}`
    );
    const isInSchedule = schedule[scheduleDay]?.active
        ? scheduleStart < zonedDate && end > zonedDate
        : false;


    const toggleChat = useCallback(() => {
        if (isPreload) {
            setPreload(false);
        }
        setWidgetShown((prev) => !prev);
    }, [isWidgetShown]);

    const preloadMessagesHandler = useCallback(() => {
        setTimeout(() => {
            setPreload(true);
        }, 10);
    }, [dialogId]);

    const UserSettings = localStorage.getItem('user_settings')


    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const getBrowserWidth = () => {
        try {
            return {width: window.top.innerWidth, type: 'top'};
        } catch (e) {
            console.warn('Нет доступа к window.top. Используем window.parent');
            try {
                return {width: window.parent.innerWidth, type: 'parent'};
            } catch (e) {
                console.warn('Нет доступа к window.parent. Используем window');
                return {width: window.innerWidth, type: 'self'};
            }
        }
    };

    useEffect(() => {
        let debounceTimeout;

        const handleResize = () => {
            clearTimeout(debounceTimeout);
            debounceTimeout = setTimeout(() => {
                const { width, type } = getBrowserWidth();
                console.log(`Ширина браузера (${type}): ${width}px`);
                setIsSmallScreen(width < 550);
            }, 200);
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            clearTimeout(debounceTimeout);
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {

        const getFontLink = () => {
            switch (currentFont) {
                case "Arial":
                    return "https://fonts.googleapis.com/css2?family=Arial&display=swap";
                case "Comic Sans":
                    return "https://fonts.googleapis.com/css2?family=Comic+Sans+MS&display=swap";
                case "Courier New":
                    return "https://fonts.googleapis.com/css2?family=Courier+New&display=swap";
                case "Georgia":
                    return "https://fonts.googleapis.com/css2?family=Georgia&display=swap";
                case "Helvetica":
                    return "https://fonts.googleapis.com/css2?family=Helvetica&display=swap";
                case "Inter":
                    return "https://fonts.googleapis.com/css2?family=Inter&display=swap";
                case "Montserrat":
                    return "https://fonts.googleapis.com/css2?family=Montserrat&display=swap";
                case "Noto Sans":
                    return "https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap";
                case "Open Sans":
                    return "https://fonts.googleapis.com/css2?family=Open+Sans&display=swap";
                case "Roboto":
                    return "https://fonts.googleapis.com/css2?family=Roboto&display=swap";
                case "Tahoma":
                    return "https://fonts.googleapis.com/css2?family=Tahoma&display=swap";
                case "Times New Roman":
                    return "https://fonts.googleapis.com/css2?family=Times+New+Roman&display=swap";
                case "Trebuchet MS":
                    return "https://fonts.googleapis.com/css2?family=Trebuchet+MS&display=swap";
                case "Verdana":
                    return "https://fonts.googleapis.com/css2?family=Verdana&display=swap";
                case "Evolventa":
                    return "https://fonts.cdnfonts.com/css/evolventa";
                default:
                    return null;
            }
        };


        const fontLink = getFontLink();

        if (fontLink) {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = fontLink;
            document.head.appendChild(link);
        }
    }, [currentFont]);

    // useEffect(() => {
    //   if (!isWidgetShown && showWidget) {
    //     setWidgetShown(true);
    //   }
    // }, [showWidget, isWidgetShown]);

    useEffect(() => {
        if (isPreload) {
            messagesRequest();
        }
    }, [isPreload]);
    useEffect(() => {
        if (messagesData?.data?.chat?.members[0]?.status === 'online') {
            setIsOnline(true)
        } else if (messagesData?.data?.chat?.members[0]?.status === 'offline') {
            setIsOnline(false)
        }
    }, [messagesData]);

    const membersData = useMemo(
        () =>
            (messagesData?.data?.status === 'unresolved' &&
                messagesData?.data?.chat?.members) ||
            [],
        [messagesData]
    );

    const sendMessage = useCallback(
        (messageText) => {
            if (messageText.trim().length === 0) return;
            setMessageText(messageText);
        },
        [dialogId]
    );

    const closeDialogFn = useCallback(
        (fromAdmin = false) => {
            if (!isWidgetShown) {
                setChatActive(false);
                setLeaveDialogBlockShown(false);
                setWidgetShown(true);
            } else if (fromAdmin) {
                setWidgetShown(false);
                setChatActive(true);
            }
        },
        [isWidgetShown]
    );

    const hideYourDataHandler = useCallback(() => {
        setIsAddYourDataVisible((prev) => !prev);
        setRequestpersonalDataShown((prev) => !prev);
    }, [isRequestpersonalDataShown, isAddYourDataVisible]);

    const closeDialogHandler = useCallback(
        (isSelfClosed = false) => {
            const closeRequest = closeDialog({
                method: 'POST',
            });
            closeRequest.then(() => {
                messagesRequest();
                closeDialogFn();
                if (isSelfClosed) {
                    setSelfClosed(true);
                }
                localStorage.setItem('dialogStatus', 'closed');
            });
        },
        [isLeaveDialogBlockShown]
    );

    const sendMessageWithActiveDialog = useCallback(
        (func) => {
            if (!dialogId) {
                fetchDialogId({
                    method: 'POST',
                });
                return func(dialogId);
            } else {
                func(dialogId);
            }
        },
        [dialogId]
    );
    const setHideDialogHandler = useCallback(() => {
        setLeaveDialogBlockShown(false);
    }, [isLeaveDialogBlockShown]);

    const onCancelHandler = useCallback(() => {
        setLeaveDialogBlockShown(true);
    }, [isLeaveDialogBlockShown]);

    const onShowRequestHandler = useCallback(() => {
        setRequestpersonalDataShown(true);
    }, [isRequestpersonalDataShown]);

    useEffect(() => {
        if (messageText.trim().length === 0 || !dialogId?.data?.id) return;
        sendMessageRequest({
            method: 'POST',
        });
    }, [messageText, dialogId?.data?.id]);

    useEffect(() => {
        if (error === null) {
            return;
        }
        setIsTokenChanged((prev) => !prev);
    }, [error]);

    useEffect(() => {
        if (isDialogActive && !dialogId?.data?.id) {
            fetchDialogId({
                method: 'POST',
            });
        }
    }, [isDialogActive, dialogId?.data?.id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTimeout(true);
        }, timeout * 1000);
        return () => clearTimeout(timer);
    }, [isTimeout]);

    useEffect(() => {
        const newMessages = [
            ...(messagesData?.data?.chat?.messages?.outcome
                ? messagesData?.data?.chat?.messages?.outcome
                : []),
            ...(messagesData?.data?.chat?.messages?.income
                ? messagesData?.data?.chat?.messages?.income
                : []),
        ];
        setMessages((prev) => [
            ...prev.filter((m) => !newMessages.find((n) => n.id === m.id)),
            ...newMessages,
        ]);
    }, [isPreload, messagesData]);

    useEffect(() => {
        //the widgets window will be opened by first message from a supporter and dialog will not have more than 1 message
        if (messages.length && messages.length < 2 && !selfClosed) {
            setWidgetShown(false);
        }
    }, [messagesData, selfClosed]);

    useEffect(() => {
        // fetcheing messages if dialog is has been created before
        if (isDialogActive && dialogId) {
            messagesRequest();
        }
    }, [isDialogActive, dialogId]);

    useEffect(() => {
        const dialogContactsHandler = () => {
            setIsAddYourDataVisible(true);
        }
        const dialogOperatorIsOnline = (data) => {
            if (data.status === 'online') {
                setIsOnline(true)
            } else if (data.status === 'offline') {
                setIsOnline(false)
            }
        };
        const dialogHandler = (data) => {
            const isTabActive = document.visibilityState === 'visible';
            if (data.type !== 'visitor' && !isTabActive) {
                window.messageAudio.play();
                window.parent.postMessage(
                    {
                        type: 'dialog.message',
                        data: data,
                    },
                    '*'
                );
            }
            messagesRequest();
            localStorage.setItem('dialogStatus', 'active');
        };

        const dialogPassHandler = () => {
            messagesRequest();
        };
        const dialogConnectHandler = () => {
            if (dialogId) {
                messagesRequest();
            }
        };
        const dialogStatusHandler = ({status}) => {
            localStorage.setItem(
                'dialogStatus',
                status === 'unresolved' ? 'active' : 'closed'
            );
            // here is need to set selfClosed to false for the case when dialog is opened by admin
            setSelfClosed(false);
            // --- the admin avatar didn't hide after dialog was closed by admin
            // need to check other cases with it
            if (dialogId?.data?.id) {
                messagesRequest();
            }
            //  --- the admin avatar didn't hide after dialog was closed by admin
            if (status === 'resolved') {
                if (!isWidgetShown) {
                    setWidgetShown(true);
                }
            } else if (status === 'unresolved') {
                if (isWidgetShown) {
                    setWidgetShown(false);
                }
                // setDialogId(id);
                // messagesRequest();
            }
        };
        socketInstance.on('dialog.contacts', dialogContactsHandler);
        socketInstance.on('dialog.pass', dialogPassHandler);
        socketInstance.on('dialog.connect', dialogConnectHandler);
        socketInstance.on('dialog.status', dialogStatusHandler);
        socketInstance.on('dialog.operator', dialogOperatorIsOnline);
        socketInstance.on('dialog.message.send', dialogHandler);
        socketInstance.on('dialog.message.edit', messagesRequest);
        socketInstance.on('dialog.message.delete', ({id}) => {
            // delete message in messages which equals to id
            setMessages((prev) => prev.filter((m) => m.id !== id));
        });

        return () => {
            socketInstance.off('dialog.contacts', dialogContactsHandler);
            socketInstance.off('dialog.pass', dialogPassHandler);
            socketInstance.off('dialog.connect', dialogConnectHandler);
            socketInstance.off('dialog.status', dialogStatusHandler);
            socketInstance.off('dialog.operator', dialogOperatorIsOnline)
            socketInstance.off('dialog.message.send', dialogHandler);
            socketInstance.off('dialog.message.edit', messagesRequest);
            socketInstance.off('dialog.message.delete', messagesRequest);
        };
    }, [dialogId, isTimeout, isWidgetShown]);
    useEffect(() => {
        // iframe styles and widget visual settings
        const iframe = window.parent.document.getElementById('chat-widget');
        const isScrollOnSmallDisplay = window.parent.document.body;
        console.log(isScrollOnSmallDisplay,'isScrollOnSmallDisplay')
        if (iframe) {
            if (!isWidgetShown) {
                iframe.style.setProperty('max-width', isSmallScreen ? '100%' : `${cW}px`);
                iframe.style.setProperty('max-height', isSmallScreen ? '100%' : `${cH}px`);
                iframe.style.setProperty(
                    'box-shadow',
                    '0px 0px 30px 0px rgba(0, 0, 0, 0.10)'
                );
                iframe.style.setProperty('border-radius', isSmallScreen ? '0' : `${radius}px`);
                isScrollOnSmallDisplay.style.overflow = isSmallScreen ? 'hidden' : undefined;
            } else {
                iframe.style.setProperty('box-shadow', 'none');
                //iframe.style.setProperty('border-radius', '50%');
                iframe.style.setProperty('max-width', `${bntSize}px`);
                iframe.style.setProperty('max-height', `${bntSize}px`);
                isScrollOnSmallDisplay.style.overflow = isSmallScreen ? '' : undefined;
            }
        }
    }, [isWidgetShown, isSmallScreen]);

    useEffect(() => {
        const iframe = window.parent.document.getElementById('chat-widget');
        if (iframe) {
            const boxShadow = iframe.style.getPropertyValue('box-shadow');
            iframe.style.setProperty('box-shadow', boxShadow);
        }
    }, [isLeaveDialogBlockShown]);

    useEffect(() => {
        // iframe styles and widget visual settings
        const iframe = window.parent.document.getElementById('chat-widget');
        const [oW, oH] = margins;
        if (iframe && !isSmallScreen) {
            switch (position) {
                case 'top-left':
                    iframe.style.setProperty('top', `${oW}px`);
                    iframe.style.setProperty('left', `${oH}px`);
                    break;
                case 'top-right':
                    iframe.style.setProperty('top', `${oW}px`);
                    iframe.style.setProperty('right', `${oH}px`);
                    break;
                case 'bottom-left':
                    iframe.style.setProperty('bottom', `${oW}px`);
                    iframe.style.setProperty('left', `${oH}px`);
                    break;
                case 'bottom-right':
                    iframe.style.setProperty('bottom', `${oW}px`);
                    iframe.style.setProperty('right', `${oH}px`);
                    break;
                default: {
                    iframe.style.setProperty('bottom', `${oW}px`);
                    iframe.style.setProperty('right', `${oH}px`);
                }
            }
        } else if (iframe && isSmallScreen) {
            iframe.style.setProperty('top', '0px');
            iframe.style.setProperty('left', '0px');
        }
    }, [isSmallScreen]);

    if (!isTimeout) return null;


    return !isWidgetShown ? (
        <MainStyled
            isRequestpersonalDataShown={isRequestpersonalDataShown}
            isLeaveDialogBlockShown={isLeaveDialogBlockShown}
            isSmallScreen={isSmallScreen}
            position={position}
            margins={margins}
            disableGutters
        >
            {isLeaveDialogBlockShown ? (
                <LeaveDialogs
                    defaultLang={defaultLang}
                    showChatHandler={setHideDialogHandler}
                    hideChatHandler={closeDialogHandler}
                />
            ) : !isRequestpersonalDataShown ? (
                <Chat
                    socketInstance={socketInstance}
                    name={name}
                    defaultLang={defaultLang}
                    copyDisabled={copyDisabled}
                    widgetId={widgetId}
                    messagesPreload={preloadMessagesHandler}
                    messagesLoading={isLoading}
                    dialogId={dialogId?.data?.id}
                    hasPreloadedMessages={messagesData?.data?.preload && !isPreload}
                    showRequest={isAddYourDataVisible}
                    isInSchedule={isInSchedule}
                    isOnline={isOnline}
                    isDialogActive={isDialogActive}
                    messages={messages}
                    visitorMessages={visitorMessages}
                    members={membersData}
                    logotype={logotype}
                    channels={channels}
                    isChatActive={isChatActive}
                    sendMessage={sendMessage}
                    sendMessageWithActiveDialog={sendMessageWithActiveDialog}
                    titleMessages={titleMessages}
                    onClick={hideYourDataHandler}
                    onClose={toggleChat}
                    onCancel={onCancelHandler}
                    onShowRequest={onShowRequestHandler}
                />
            ) : (
                <EnterYourData
                    defaultLang={defaultLang}
                    dialogId={dialogId?.data?.id}
                    id={dialogId?.data?.id}
                    fields={requestedFields}
                    onClose={hideYourDataHandler}
                />
            )}
        </MainStyled>
    ) : (
        <ChatEnterButton onClick={toggleChat} messagesAmont={1}/>
    );
};

const ChatEnterButton = memo(({onClick, sx, messagesAmont = ''}) => {
    return (
        <Box
            onClick={onClick}

            sx={(theme) => {
                return {
                    position: 'fixed',
                    width: '64px',
                    height: '64px',
                    p: 2,
                    bgcolor: 'button.main',
                    borderRadius: theme.palette.button.borderRadius + 'px !important',
                    cursor: 'pointer',
                    ...sx,
                };
            }}
        >
            <Box
                component={ChatIcon}
                sx={(theme) => {
                    return {
                        '& path': {
                            fill: theme.palette.button.icon,
                        },
                    };
                }}
            >
                {messagesAmont}
            </Box>
        </Box>
    );
});

const LeaveDialogs = memo(
    ({showChatHandler, hideChatHandler, defaultLang}) => {
        const [isShowConfirm, setShowConfirm] = useState(false);

        const toggleConfirm = useCallback(
            () => setShowConfirm((prev) => !prev),
            [isShowConfirm]
        );

        return !isShowConfirm ? (
            <Dialog
                title={translates[defaultLang].cancel_dialog}
                onCancel={showChatHandler}
                onSend={toggleConfirm}
                applyText={translates[defaultLang].close}
                cancelText={translates[defaultLang].cancel}
            />
        ) : (
            <Dialog
                title={translates[defaultLang].complete_askings}
                onCancel={hideChatHandler}
                onSend={hideChatHandler}
                applyText={translates[defaultLang].apply_btn}
                cancelText={translates[defaultLang].cancel_btn}
            />
        );
    }
);
