import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom/client';
import io from 'socket.io-client';
import { detectIncognito } from "detectincognitojs";
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import useHttp from './hooks/useHttp';
import useSetIframeStyles from './hooks/useSetIframeStyles';
import { getAthorisationSearchParams } from './utils/getAthorisationSearchParams';
import { base64Audio } from './constants';

const authorisationSearchParams = getAthorisationSearchParams();

const audio = new Audio(base64Audio);
window.messageAudio = audio;

const Page = () => {
  const [socketInstance, setSocketInstance] = useState(null);
  const widgetId = useRef(localStorage.getItem('widgetId'));
  const [isTokenChanged, setIsTokenChanged] = useState(false);
  const [isIncognito, setIsIncognito] = useState(null);
  const [isWidgetBlocked, setIsWidgetBlocked] = useState(false);
  const [token, setToken] = useState(null);
  const [authorisationData, authorisationRequest, error] = useHttp(
      `widget/${widgetId.current}?${authorisationSearchParams}`
  );

  useSetIframeStyles({
    display: isWidgetBlocked ? 'none' : 'block',
  });

  const fetchAuthorisationData = useCallback(() => {
    authorisationRequest({
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }, [authorisationRequest]);

  const initializeIncognitoState = async () => {
    const result = await detectIncognito();
    const isIncognitoMode = Number(result.isPrivate);
    localStorage.setItem('isIncognito', isIncognitoMode);
    setIsIncognito(isIncognitoMode);
  };

  useEffect(() => {
    initializeIncognitoState();
  }, []);

  useEffect(() => {
    if (isIncognito !== null) {
      if (!isWidgetBlocked) {
        fetchAuthorisationData();
        setIsWidgetBlocked(false);
      } else {
        setIsWidgetBlocked(true);
      }
    }
  }, [isWidgetBlocked, error, isIncognito]);

  useEffect(() => {
    if (error) {
      setIsWidgetBlocked(true);
    }
  }, [error]);

  useEffect(() => {
    if (authorisationData && authorisationData.data && authorisationData.data.token) {
      const { token, expires, refresh, refresh_expires, id: sc_visitor } = authorisationData.data;

      localStorage.setItem('token', token);
      localStorage.setItem('expires', expires);
      localStorage.setItem('refresh', refresh);
      localStorage.setItem('refresh_expires', refresh_expires);
      localStorage.setItem('sc_visitor', sc_visitor);

      setToken(token);
    }
  }, [authorisationData]);

  const initializeSocket = useCallback(() => {
    if (socketInstance) {
      socketInstance.off('visitor.status');
      socketInstance.disconnect();
    }

    const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
      query: { token },
      reconnection: true,
      reconnectionDelay: 500,
    });

    const visitorStatusHandler = (data) => {
      if (data.status === 'blocked') {
        setIsWidgetBlocked(true);
      } else {
        setIsWidgetBlocked(false);
        fetchAuthorisationData();
      }
    };


    newSocket.on('visitor.status', visitorStatusHandler);
    setSocketInstance(newSocket);
  }, [token, fetchAuthorisationData, socketInstance]);

  useEffect(() => {
    if (!token) {
      return
    }
    initializeSocket();

    return () => {
      if (socketInstance) {
        socketInstance.off('visitor.status');
        socketInstance.disconnect();
      }
    };
  }, [token]);

  return (
      <App
          widgetId={widgetId.current}
          socketInstance={socketInstance}
          isWidgetBlocked={isWidgetBlocked}
          token={token}
          setIsTokenChanged={setIsTokenChanged}
          initializeSocket={initializeSocket} // Передаём функцию в App
      />
  );
};

const root = ReactDOM.createRoot(document.getElementById('widget'));
root.render(<Page />);

reportWebVitals();
