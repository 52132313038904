import React, { useState, memo, useEffect, useRef, forwardRef } from 'react';
import { Stack, TextField, Box } from '@mui/material';
import styled from '@emotion/styled';

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';

import useHttp from '../../hooks/useHttp';

import { Dialog } from '../Dialog/Dialog';

import { useInput } from '../../hooks/useInput';

import { translates } from '../../constants/translates';

import img from '../../assets/icons/arrow-left.svg';

const widgetId = localStorage.getItem('widgetId');

const isError = (value, isSending, errorType) =>
  isSending &&
  (value.isDirty ||
    value.isEmpty ||
    !value.inputValid ||
    (errorType && value[errorType]));

export const EnterYourData = memo(
  ({ onClose, fields, dialogId, defaultLang }) => {
    const [sendData, setSendData] = useState(null);
    const [_, request] = useHttp(
      `widget/${widgetId}/dialog/${dialogId}/request?${sendData}`
    );
    const [isSending, setIsSending] = useState(false);

    const email = useInput('', {
      isEmpty: true,
      emailError: false,
    });
    const surname = useInput('', {
      isEmpty: true,
      minLength: 2,
      maxLength: 50,
      nameError: false,
    });
    const name = useInput('', {
      isEmpty: true,
      minLength: 2,
      maxLength: 50,
      nameError: false,
    });
    const patronymic = useInput('', {
      isEmpty: true,
      minLength: 2,
      maxLength: 50,
      nameError: false,
    });
    const phone = useInput('', {
      isEmpty: true,
      phoneError: false,
    });

    const phoneInputRef = useRef(null);

    const existsFields = fields.filter((field) => field.active);

    const valuesObj = {
      email,
      surname,
      name,
      patronymic,
      phone,
    };

    const onSubmit = (e) => {
      e.preventDefault();

      const check = (fields) =>
        fields.every(({ field }) => valuesObj[field].inputValid);
      setIsSending(true);

      if (check(existsFields)) {
        const data = existsFields.reduce((acc, { field }) => {
          acc[field] = valuesObj[field].value;
          return acc;
        }, {});
        setSendData(new URLSearchParams(data));
      }
    };

    const phoneChangeHandler = (value, _, e) => {
      phone.onChange(e);
      phone.setValue(value);
      phoneInputRef.current.numberInputRef.focus();
    };

    useEffect(() => {
      if (sendData) {
        request({
          method: 'POST',
        });
        onClose();
      }
    }, [sendData]);

    return (
      <Dialog
        defaultLang={defaultLang}
        onCancel={onClose}
        onSend={onSubmit}
        component="form"
        onSubmit={onSubmit}
        title={translates[defaultLang].give_us_your_data}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit(e);
          }
        }}
      >
        <Stack spacing={2.95}>
          {fields.some((field) => field.field === 'name' && field.active) && (
            <TextField
              error={isError(name, isSending)}
              size="small"
              id="outlined-basic"
              label={translates[defaultLang].first_name}
              variant="outlined"
              value={name.value}
              onChange={name.onChange}
              style={{
                backgroundColor: 'white',
              }}
            />
          )}
          {fields.some(
            (field) => field.field === 'surname' && field.active
          ) && (
            <TextField
              error={isError(surname, isSending)}
              size="small"
              id="outlined-basic"
              label={translates[defaultLang].last_name}
              variant="outlined"
              value={surname.value}
              onChange={surname.onChange}
              style={{
                backgroundColor: 'white',
              }}
            />
          )}
          {fields.some(
            (field) => field.field === 'patronymic' && field.active
          ) && (
            <TextField
              error={isError(patronymic, isSending)}
              size="small"
              id="outlined-basic"
              label={translates[defaultLang].father_name}
              variant="outlined"
              value={patronymic.value}
              onChange={patronymic.onChange}
              style={{
                backgroundColor: 'white',
              }}
            />
          )}
          {fields.some((field) => field.field === 'email' && field.active) && (
            <TextField
              error={isError(email, isSending, 'emailError')}
              size="small"
              id="outlined-basic"
              label={translates[defaultLang].email}
              variant="outlined"
              value={email.value}
              onChange={email.onChange}
              style={{
                backgroundColor: 'white',
              }}
            />
          )}
          {fields.some((field) => field.field === 'phone' && field.active) && (
            <PhoneSelectStyled
              ref={phoneInputRef}
              value={phone.value}
              onChange={phoneChangeHandler}
              error={isError(phone, isSending, 'phoneError')}
              isValid={() => !isError(phone, isSending, 'phoneError')}
              maxLength={12}
            />
          )}
        </Stack>
      </Dialog>
    );
  }
);

const PhoneSelect = forwardRef(
  ({ fieldName, dropdownWidth, ...props }, ref) => {
    return (
      <Box
        component={PhoneInput}
        ref={ref}
        name={fieldName}
        country={'ua'}
        specialLabel={''}
        {...props}
      />
    );
  }
);

const PhoneSelectStyled = styled(PhoneSelect)(({ theme }) => ({
  width: '100%',
  fontFamily: 'inherit',
  '&:hover .form-control': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  '&.react-tel-input .form-control:focus': {
    borderColor: theme.palette.primary.main,
  },
  '&.react-tel-input .form-control:focus': {
    borderColor: theme.palette.primary.main,
    boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
  },
  '& .form-control': {
      fontFamily: theme.typography.fontFamily,
    paddingTop: theme.spacing(1.45),
    paddingBottom: theme.spacing(1.45),
    height: '40px',
    // borderRadius: '0!important',
    width: '100%',
    // color: theme.palette.grey.dark,
    backgroundColor: theme.palette.primary.background,
    backgroundColor: '#fff',
  },
  '&.react-tel-input .form-control.invalid-number': {
    borderColor: theme.palette.error.main,
    '&:focus': {
      borderColor: theme.palette.error.main,
      boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
    },
  },
  '& .country-list': {
    width: '260px',
    // borderRadius: '0!important',
  },
  '& .selected-flag': {
    padding: '0 14px',

    '& .arrow': {
      top: '-11px',
      left: '24px',
      border: 'none',
      marginTop: '1px',
      width: '22px',
      height: '22px',
      backgroundImage: `url(${img})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      transform: 'translateY(50%) rotate(-90deg)',
      transition: 'transform 0.250s',
      transformOrigin: 'center center',
    },

    '&:focus .arrow, & .arrow.up': {
      border: 'none',
    },

    '& .arrow.up': {
      transform: 'translateY(50%) rotate(90deg)',
    },
  },
}));
